import {commodities} from "./api.calls";
var _ = require("lodash");
function farmer(fmr){
    this.name = fmr.name;
    this.phone_no = fmr.phone_no;
    this.display_phone = false;
}
function paginator(count){
    this.perPage = 5;
    this.rows = count;
    this.currentPage = 1;
    this.offset = ()=>{
        return (this.perPage*this.currentPage) - this.perPage;
    }
    this.to_query=()=>{
        return [["offset",this.offset()],["limit",this.perPage]]
    }
}
/**
 * 
 * @param {string} field 
 */
async function spread(field){
    const [smax,smin] = await Promise.all([commodities.summary(field,"max"),commodities.summary(field,"min")]);
    function extractor(info,kind){
        return info.map(el=>{return {"value_chain":el.value_chain,[kind]:el["stat"]}});
    }
    const merged = _.merge(_.keyBy(extractor(smax,"smax"), 'value_chain'),
     _.keyBy(extractor(smin,"smin"), 'value_chain'));
    const values = _.values(merged);
    return values;
}
export {farmer,paginator,spread}