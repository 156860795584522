<template>
    <div class="pt-2">
        <b-radio-group :options="stats" v-model="stat" @change="fetchData"></b-radio-group>
        <b-table :fields="fields" :items="items" show-empty class="mt-2">
            <template #empty>
                No items yet.
            </template>
            <template #cell(smin)="data">
                {{data.item.smin.toLocaleString()}}
            </template>
            <template #cell(smax)="data">
                {{data.item.smax.toLocaleString()}}
            </template>
        </b-table>
    </div>
</template>
<script>
import * as ui from "../ui.utils";
export default {
    data(){
        return {
            stat:"unit_price",
            stats:[{text:"Price (UGX)",value:"unit_price"},{text:"Weight (KG)",value:"weight"}],
            fields:[
                {
                    key:"value_chain",
                    label:"Crop"
                },
                {
                    key:"smin",
                    label:"Min.",
                    
                },
                {
                    key:"smax",
                    label:"Max."
                }
            ],
            items:[]
        }
    },
    mounted(){
        this.fetchData();
    },
    methods:{
        fetchData(){
            ui.spread(this.stat).then(data=>{
                this.items=data;
            });
        }
    }
}
</script>