<template>
  <div>
    <div class="border-bottom mt-2">
      <h3 class="pl-3">MARKET PLACE</h3>
      <b-navbar>
        <b-form inline @reset="onReset">
          <b-form-select
            class="mb-2 mr-sm-2 mb-sm-0"
            v-model="crop"
            :options="crops"
            @change="onSelectCrop"
          >
            <template #first>
              <b-form-select-option :value="null"
                >All Crops</b-form-select-option
              >
            </template>
          </b-form-select>
          <label class="ml-1">Sort By:</label>
          <b-form-select
            class="mb-2 mr-sm-2 mb-sm-0 mr-1"
            v-model="sort_by"
            @change="getCommodities"
            :options="features"
          >
          </b-form-select>
          <b-form-radio-group v-model="sort_dir" :options="sort_dirs" @change="getCommodities">
          </b-form-radio-group>
          <b-form-select
            class="mb-2 mr-sm-2 mb-sm-0"
            v-model="district"
            @change="onSelectDistrict"
            :options="districts"
          >
            <template #first>
              <b-form-select-option :value="null"
                >All Districts</b-form-select-option
              >
            </template>
          </b-form-select>
          <!-- <b-form-select
            class="mb-2 mr-sm-2 mb-sm-0"
            v-model="region"
            :options="regions"
          >
            <template #first>
              <b-form-select-option :value="null"
                >All Regions</b-form-select-option
              >
            </template>
          </b-form-select>
          &nbsp; -->
          <b-button type="reset" variant="danger">
            <b-icon-bootstrap-reboot></b-icon-bootstrap-reboot> Reset
          </b-button>
        </b-form>
        <b-navbar-nav class="ml-auto">
          <button class="btn-opt primary" @click="refreshView">
            <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
            Refresh
          </button>
        </b-navbar-nav>
      </b-navbar>
    </div>
    <div class="d-flex">
      <div class="mt-2 w-comms pl-2">
        <b-overlay
          :show="commodities_status == 'loading'"
          spinner-variant="primary"
        >
          <b-list-group>
            <b-list-group-item
              v-for="commodity in commodities"
              @click="viewCommodity(commodity)"
              button
              :key="commodity.id"
              v-b-modal.acomm
              class="flex-column align-items-start"
            >
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">
                  {{ commodity.value_chain }}
                </h5>
                <div>
                  <b-badge variant="secondary" class="mr-1 text-badge py-2">
                    <b-icon-clock></b-icon-clock>
                    <small class="pl-1">{{
                      commodity.date | elapsed_time
                    }}</small>
                  </b-badge>
                  <b-badge variant="secondary" class="text-badge py-2 mr-1">
                    <b-icon-eye></b-icon-eye>
                    <small class="pl-1">{{ commodity.views }} views</small>
                  </b-badge>
                </div>
              </div>
              <p class="pt-1 text-dark text-capitalize" v-show="commodity.variety && commodity.variety!='NA'">
                {{commodity.variety}}
              </p>
              <p class="my-1 text-dark">
                <b-icon-geo-alt-fill variant="dark"></b-icon-geo-alt-fill>
                {{ commodity | location }}
              </p>

              <div class="d-flex w-100 justify-content-between">
                <div>
                  <span class="font-weight-bold">{{
                    commodity.price | currency
                  }}</span>
                  <span class="text-muted"><small>&nbsp;UGX</small></span>
                </div>
                <div>
                  <span class="font-weight-bold">{{
                    commodity.weight | fmt_num
                  }}</span>
                  <span class="text-muted"><small>&nbsp;KG</small></span>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-overlay>
        <b-pagination
          v-model="paginator.currentPage"
          :total-rows="paginator.rows"
          :per-page="paginator.perPage"
          @input="getCommodities"
          class="mt-2 ml-auto"
          aria-controls="my-table"
        ></b-pagination>
      </div>
      <div class="col-5">
        <market-summary></market-summary>
        <b-table
          v-show="crop != null"
          :fields="location_fields"
          :items="locations"
          :busy="locations_loading"
          :caption="crop"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <b-modal id="acomm" hide-footer title="Product Details" size="md">
      <b-list-group>
        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <div>Name</div>
            <div>
              {{ acommodity.value_chain | fmt_name(acommodity.variety) }}
            </div>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <div>Price</div>
            <div>
              <span class="font-weight-bold">{{ acommodity.unit_price }}</span>
              <span class="text-muted"><small>&nbsp;UGX</small></span>
            </div>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <div>Quantity</div>
            <div>
              <span class="font-weight-bold">{{ acommodity.weight }}</span>
              <span class="text-muted"><small>&nbsp;KG</small></span>
            </div>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <div>Total Cost</div>
            <div>
              <span class="font-weight-bold">{{ acommodity.price | currency}}</span>
              <span class="text-muted">&nbsp; UGX</span>
            </div>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-between">
            <div>Location</div>
            <div>
              {{ acommodity | location }}
            </div>
          </div>
        </b-list-group-item>
        <b-list-group-item>
          <div class="d-flex justify-content-between align-items-center">
            <div>Farmer</div>
            <div>
              <ul class="list-unstyled list-inline">
                <li class="list-inline-item">
                  {{ acommodity.farmer.name }}
                </li>
                <li class="list-inline-item">
                  <b-button
                    v-b-toggle.farmer-tel
                    variant="link"
                    v-show="!acommodity.farmer.display_phone"
                  >
                    <small>contact &#9660;</small>
                  </b-button>
                  <b-collapse
                    id="farmer-tel"
                    v-model="acommodity.farmer.display_phone"
                  >
                    <b-link>{{ acommodity.farmer.phone_no }}</b-link>
                  </b-collapse>
                </li>
              </ul>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </div>
</template>
<style scoped>
.text-badge {
  font-size: 1rem;
}
.w-comms {
  width: 38%;
}
</style>
<script>
import moment from "moment";
import * as api from "./api.calls";
import millify from "millify";
import { farmer, paginator } from "./ui.utils";
import MarketSummary from "./components/MarketSummary.vue";
export default {
  components: { MarketSummary },
  data() {
    return {
      crops: [],
      crop: null,
      districts: [],
      district: null,
      regions: [],
      region: null,
      locations: [],
      location_fields: ["district", { key: "t_weight", label: "Quantity" }],
      locations_loading: false,
      features: [
        { text: "Date Received", value: "date" },
        { text: "Price", value: "unit_price" },
      ],
      sort_by: "date",
      paginator: new paginator(1),
      commodities: [],
      commodities_status: "loading",
      acommodity: { id: "", farmer: new farmer({ name: "", phone_no: "" }),price:0 },

      sort_dirs: [
        {
          text: "Ascending",
          value: "ASC",
        },
        {
          text: "Descending",
          value: "DESC",
        },
      ],
      sort_dir: "DESC",
    };
  },
  filters: {
    fmt_name: (vchain, variety) => {
      let tmp = variety == undefined ? "" : variety;
      return vchain + " " + tmp;
    },
    elapsed_time: (date) => {
      return moment(date).fromNow();
    },
    currency: (num) => {
      return num.toLocaleString();
    },
    fmt_num: (num) => {
      return millify(num);
    },
    /**
     * @param {{district:string,village:string,region:string}} commodity
     * @returns {string}
     */
    location({ district, village, region }) {
      return [village, district, region].join(", ");
    },
  },
  mounted() {
    this.getCommodities();
    api.commodities.crops().then((data) => (this.crops = data));
    api.commodities.districts().then((data) => (this.districts = data));
    api.commodities.regions().then((data) => (this.regions = data));
  },
  methods: {
    getCommodities() {
      this.commodities_status = "loading";
      let filters = [];
      if (this.crop) {
        filters.push(["value_chain", this.crop]);
      }
      if (this.district) {
        filters.push(["district", this.district]);
      }
      if (this.region) {
        filters.push(["region", this.region]);
      }
      filters.push([
        "sort_by",
        this.sort_by + "." + this.sort_dir.toLowerCase(),
      ]);
      const query = this.paginator.to_query().concat(filters);
      api.commodities.get(query).then((data) => {
        this.commodities = data.items;
        this.paginator.rows = data.row_count;
        this.commodities_status = "done";
      });
    },
    viewCommodity(commodity) {
      api.commodities.view(commodity.id).then((data) => {
        const comms = this.commodities;
        const idx = comms.findIndex((el) => el.id == commodity.id);
        comms[idx] = data;
        this.$set(this.commodities,idx,data);
      });
      this.acommodity = { ...commodity, farmer: new farmer(commodity.farmer) };
    },
    onReset(event) {
      event.preventDefault();
      this.crop = null;
      this.sort_dir = "DESC";
      this.sort_by = "date";
      this.district = null;
      this.region = null;
      this.$nextTick(()=>{
        this.getCommodities();
      })
    },
    refreshView() {
      this.$router.go(0);
    },
    onSelectCrop() {
      this.getCommodities();
      this.locationSummary();
    },
    onSelectDistrict() {
      this.locationSummary();
      this.getCommodities();
    },
    locationSummary() {
      if (this.crop) {
        this.locations_loading = true;
        api.commodities
          .locationSummary(this.crop)
          .then((data) => (this.locations = data)).finally(()=>{
            this.locations_loading = false;
          });
      }
    },
  },
};
</script>