import {axiosApiInstance} from "@/plugins/axios.js"

/**
 * @typedef Commodity
 * @property {string} value_chain
 * @property {string} id
 * @property {string} variety
 * @property {string} date
 * @property {string} weight
 * @property {string} location
 * @property {string} views
 * @property {id:string,phone_no:string,name:string} farmer
 */

const comms = {
    baseUrl:process.env.VUE_APP_BACKEND+"/commodities"
}

const commodities = {
    /**
     * @param {Array<Array<string>>} filters
     * @returns {Promise<{items:Commodity[],row_count:number}>}
     */
    get:async (filters)=>{
        const params = new URLSearchParams(filters)
        const res = await axiosApiInstance({
            url:comms.baseUrl,
            params
        });
        return res.data;
    },
    crops:async ()=>{
        const res = await axiosApiInstance({
            url:comms.baseUrl+"/crops"
        });
        return res.data;
    },
    /**
     * @param {string} id
     * @returns {Promise<Commodity>}
     */
    view:async(id)=>{
        const res = await axiosApiInstance({url:comms.baseUrl+"/"+id+"/:view",method:"post"});
        return res.data
    },
    /**
     * 
     * @param {string} field 
     * @param {string} func 
     */
    summary:async(field,func)=>{
        const key = "aggregate["+func+"]";
        const qparams = {[key]:field};
        const res = await axiosApiInstance({url:comms.baseUrl+"/:summary",params:qparams});
        return res.data
    },
    /**
     * 
     * @returns {Promise<string[]>}
     */
    districts:async()=>{
        const res = await axiosApiInstance({url:comms.baseUrl+"/:districts"});
        return res.data;
    },
    /**
     * @returns {Promise<string[]>}
     */
    regions:async()=>{
        const res = await axiosApiInstance({url:comms.baseUrl+"/:regions"});
        return res.data;
    },
    /**
     * 
     * @param {string} crop 
     * @returns {Promise<?>}
     */
    locationSummary:async(crop)=>{
        const fmt_crop = encodeURIComponent(crop);
        const res = await axiosApiInstance({url:comms.baseUrl+"/:location_summary/"+fmt_crop});
        return res.data;
    }
} 
export {commodities};